import React from 'react';
import { PortableText } from '@portabletext/react';
import BlockContent from '@sanity/block-content-to-react';
import { Link } from "gatsby";
import * as styles from './pageText.module.scss';

const PageText = ({ value }) => {
  return (
    <div className={styles.blockContentHarness}>
      <div className={styles.blockContent}>
        <PortableText
          value={value}
          components={{
            block: {},
            marks: {
              superscript: ({ children }) => <sup>{children}</sup>,
              link: ({ children, value }) => {
                let { blank, href } = value;
                if (!href) return <>{children}</>; // clicked 'link' but not filled it in yet

                const isMailTo = href.includes('mailto:');
                if (isMailTo) return <a href={href}>{children}</a>

                const domains = ['bepodium.com', 'localhost', '192.168.'];
                const isInternal = domains.some((domain) => href.includes(domain));
                if (isInternal) href = href.replace(/^.*\/\/[^\/]+/, '');

                return isInternal
                  ? <Link to={href}>{children}</Link>
                  : <a href={href} target={blank ? '_blank' : '_self'} rel="noreferrer noopener">{children}</a>
              }
            }
          }}
        />
      </div>
    </div>
  )
}

export default PageText;