import React from "react";
import Layout from "../components/layout/Layout";
import Seo from "../components/Seo";
import PageText from '../components/text/PageText';
import { PortableText } from '@portabletext/react';

const TermsTemplatePage = ({ location, pageContext: { data } }) => (
  <Layout location={location} >
    <Seo title={data.pageTitle} location={location} />
    <PageText value={data._rawContent} />
  </Layout>
);

export default TermsTemplatePage;